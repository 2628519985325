import * as React from "react";

import { cn } from "@/lib/utils";
import { Card, CardContent } from "@/components/ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { type CarouselApi } from "@/components/ui/carousel";
import type { EmblaCarouselType, EmblaEventType } from "embla-carousel-react";
import useCarouselTipEffect from "@/lib/useCarouselTipEffect";

export interface CarouselData {
  title: string;
  description: string;
  imgSrc: string;
  textColor: string;
  bg: string;
  bgBottomPosition: number;
}

export interface CarouselCardsProps
  extends React.HTMLAttributes<HTMLDivElement> {
  data: CarouselData[];
}

export const CarouselCards = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & CarouselCardsProps
>(({ className, data, ...props }, ref) => {
  const [api, setApi] = React.useState<CarouselApi>();

  const MAX_FONT_SIZE = 64;
  const MIN_FONT_SIZE = 24;
  const MAX_LINE_HEIGHT = 56.7;
  const [activeIndex, setActiveIndex] = React.useState(0);

  // Function to update the active index
  const updateIndexButtonPress = (direction: string) => {
    setActiveIndex((prevIndex) => {
      if (direction === "next") {
        return (prevIndex + 1) % data.length;
      } else {
        return (prevIndex - 1 + data.length) % data.length;
      }
    });
  };

  const onSelect = React.useCallback(
    (emblaApi: EmblaCarouselType, eventName: EmblaEventType) => {
      const index = emblaApi.selectedScrollSnap();
      setActiveIndex(index);
    },
    [],
  );

  React.useEffect(() => {
    if (!api) {
      return;
    }

    api.on("select", onSelect);

    return () => {
      api?.off("select", onSelect);
    };
  }, [api, onSelect]);

  const isOverflowing = (element: HTMLElement) => {
    const parent = element.parentElement;

    if (!parent) {
      return false;
    }

    const isOverflowingByScroll =
      element.scrollWidth > element.clientWidth ||
      element.scrollHeight > element.clientHeight ||
      element.scrollHeight >
        parent.clientHeight -
          (parseInt(window.getComputedStyle(element).paddingBottom) +
            parseInt(window.getComputedStyle(element).paddingTop) / 5);

    return isOverflowingByScroll;
  };

  const adjustFontSize = (
    elementId: string,
    maxFontSize: number,
    minFontSize: number,
    maxLineHeight: number,
  ) => {
    const element = document.getElementById(elementId);
    if (!element) return;

    let start = minFontSize;
    let end = maxFontSize;
    let mid;

    const lineHeightRatio = maxLineHeight / maxFontSize;

    while (start <= end) {
      mid = Math.floor((start + end) / 2);
      const lineHeight = mid * lineHeightRatio;

      element.style.fontSize = `${mid}px`;
      element.style.lineHeight = `${lineHeight}px`;

      if (isOverflowing(element)) {
        // Decrease font size
        end = mid - 1;
      } else {
        // Increase font size
        start = mid + 1;
      }
    }

    // Apply the largest font size that doesn't cause overflow
    const finalFontSize = end;
    const finalLineHeight = finalFontSize * lineHeightRatio;
    element.style.fontSize = `${finalFontSize}px`;
    element.style.lineHeight = `${finalLineHeight}px`;
    element.classList.remove("hidden");
  };

  const descriptionRefs = React.useRef<Array<React.RefObject<HTMLDivElement>>>(
    [],
  );

  React.useEffect(() => {
    descriptionRefs.current.forEach((ref) => {
      if (ref.current) {
        adjustFontSize(
            ref.current.id,
            MAX_FONT_SIZE,
            MIN_FONT_SIZE,
            MAX_LINE_HEIGHT,
        );
      }
    });
  }, [data.length]);


  descriptionRefs.current = data.map(
    (_, i) => descriptionRefs.current[i] ?? React.createRef<HTMLDivElement>(),
  );

  useCarouselTipEffect(api);

  React.useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const elementId = entry.target.id;
        if (document.getElementById(elementId)) {
          adjustFontSize(elementId, MAX_FONT_SIZE, MIN_FONT_SIZE, MAX_LINE_HEIGHT);
        }
      });
    });

    descriptionRefs.current.forEach((ref) => {
      if (ref.current) observer.observe(ref.current);
    });

    return () => observer.disconnect();
  }, [data.length]);

  React.useEffect(() => {
    const nextButton = document.getElementById("next");
    const prevButton = document.getElementById("prev");

    const handleNextClick = () => updateIndexButtonPress("next");
    const handlePrevClick = () => updateIndexButtonPress("prev");

    // Add event listeners
    if (nextButton) nextButton.addEventListener("click", handleNextClick);
    if (prevButton) prevButton.addEventListener("click", handlePrevClick);

    // Clean up
    return () => {
      if (nextButton) nextButton.removeEventListener("click", handleNextClick);
      if (prevButton) prevButton.removeEventListener("click", handlePrevClick);
    };
  }, [data.length]);

  const textColor = data[activeIndex]?.textColor || "text-primary";
  const color = textColor.replace("text-", "").replace("-foreground", "");
  let hoverColor =
    `hover:text-${color}-foreground` || "text-primary-foreground";
  let bgHoverColor = `hover:bg-${color}-foreground` || "bg-primary-foreground";
  if (textColor.endsWith("-foreground")) {
    hoverColor = `${hoverColor.replace("-foreground", "")}`;
  } else {
    bgHoverColor = `${bgHoverColor.replace("-foreground", "")}`;
  }
  const cacheColors = cn(
    "hover:bg-primary-foreground",
    "hover:bg-primary",
    "hover:bg-secondary",
    "hover:bg-secondary-foreground",
    "hover:bg-popover",
    "hover:bg-popover-foreground",
    "hover:bg-accent",
    "hover:bg-accent-foreground",
    "hover:bg-muted",
    "hover:bg-muted-foreground",
    "hover:text-primary",
    "hover:text-secondary",
    "hover:text-popover",
    "hover:text-accent",
    "hover:text-muted",
    "hover:text-primary-foreground",
    "hover:text-secondary-foreground",
    "hover:text-popover-foreground",
    "hover:text-accent-foreground",
    "hover:text-muted-foreground",
  );
  const navigationButtonClassNames = cn(
    cacheColors,
    textColor,
    hoverColor,
    bgHoverColor,
  );

  return (
    <Carousel setApi={setApi} className={cn(" w-full", className)}>
      <CarouselContent className="-ml-0">
        {data.map((item, index) => (
          <CarouselItem key={index} className=" pl-0">
            <div>
              <Card className="relative  w-full p-0">
                <CardContent
                  className={cn(
                    "flex max-h-[688px] w-full flex-col items-center justify-start p-0 pt-6 ",
                    `${item.bg} ${item.textColor}  `,
                  )}
                >
                  <div className=" flex min-h-[300px] w-full flex-col justify-start   px-10 pt-10 max-[1230px]:pt-6">
                    <div className="  text-Header6">{item.title}</div>
                    <div
                      id={`description-${index}`}
                      ref={descriptionRefs.current[index]}
                      className="box-border hidden h-full pb-14 pt-12 text-Header3 max-sm:pb-12 max-sm:pt-10"
                    >
                      {item.description}
                    </div>
                  </div>
                  <div
                    className="flex min-h-[364px] w-full flex-col-reverse items-start justify-start  pb-6 pl-3"
                    style={{
                      backgroundImage: `url(${item.imgSrc})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "top",
                      backgroundPositionY: `${item.bgBottomPosition}%`,
                    }}
                  >
                    <div className=" text-Header3 text-white ">
                      {String(index + 1).padStart(2, "0")}
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </CarouselItem>
        ))}
      </CarouselContent>
      <div
        className={cn(
          "w-30 absolute right-[68px] top-[77px] flex flex-row  max-[1230px]:top-[60px] ",
        )}
      >
        <CarouselPrevious
          className={cn("-left-4 border-0", navigationButtonClassNames)}
          id="prev"
        />
        <CarouselNext
          className={cn("border-0", navigationButtonClassNames)}
          id="next"
        />
      </div>
    </Carousel>
  );
});
